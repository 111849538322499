<template>
    <seccion-datos 
        :titulo="titulo" 
        @cambios="guardar($data)" 
        tipo="info"
        >
        <div class="row">
            <div class="col-md-6">
                <!-- Inicio IVA -->
                <div class="card card-success card-outline">                            
                    <div class="card-body ">
                        <h5>{{$t('general.impuesto')}}</h5>
                        <select 
                            class="custom-select form-control-sm"
                            v-model="iva_trabajo_defecto"
                            >
                            <option value=''>{{$t('general.seleccionaimpuesto')}}</option>
                            <option 
                                v-for="impuesto in todosImpuestosActivos"
                                :key="impuesto.id" 
                                :value="impuesto.valor_iva">{{ nombreImpuesto(impuesto) }}</option>                                    
                        </select>
                    </div>
                </div>
                <!-- Fin IVA -->
            </div>
            <div class="col-md-6">
                <!-- Inicio Destinatrio -->
                <div class="card card-danger card-outline">                            
                    <div class="card-body ">
                        <h5>{{$t('general.destinatario')}}</h5>
                        <select 
                            class="custom-select form-control-sm"
                            v-model="destinatario_trabajo_defecto"
                            >
                            <option value=''>{{ $t('general.seleccionedestinatario')}}</option>
                            <option value="Compañia">{{$t('general.compania')}}</option>
                            <option value="Cliente">{{$t('general.asegurado')}}</option>                                    
                        </select>
                    </div>
                </div>
                <!-- Fin Destinatario -->
            </div>
        </div>
        <!-- Inicio Descuentos -->
        <div class="card card-warning card-outline">                            
            <div class="card-body ">
                <h5>{{$t('general.descuentos')}}</h5>
                <div class="row">
                    <div class="col-md-6">
                        <label>{{ $t('general.trabajosacompaniaasegurado')}}</label>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-group">                                                
                                    <input 
                                        type="number" 
                                        class="form-control form-control-sm" 
                                        id="descuento-trabajos-cia" 
                                        placeholder=""
                                        v-model="descuento_trabajo_defecto"
                                        >
                                    <div class="input-group-append">
                                        <span class="input-group-text form-control-sm">%</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <select 
                                    class="custom-select form-control-sm"
                                    v-model="descuento_trabajo_defecto_tipo_factura"
                                    >
                                    <option value="0">{{$t('general.todos')}}</option>
                                    <option value="1">{{$t('general.facturas')}}</option>
                                    <option value="2">{{$t('general.presupuestos')}}</option>                                    
                                </select>
                            </div>
                            
                        </div>
                        <textarea 
                            class="form-control" 
                            rows="3" 
                            placeholder="Enter ..."
                            v-model="descuento_concepto_trabajo_defecto"
                            ></textarea>
                    </div>
                    <div class="col-md-6">
                        <label>{{$t('general.trabajosaoperario')}}</label>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-group">                                                
                                    <input 
                                        type="number" 
                                        class="form-control form-control-sm" 
                                        id="descuento-trabajos-operario" 
                                        placeholder=""
                                        v-model="descuento_operario_defecto"
                                        >
                                    <div class="input-group-append">
                                        <span class="input-group-text form-control-sm">%</span>
                                    </div>
                                </div>
                            </div>                                        
                        </div>
                        <textarea 
                            class="form-control" 
                            rows="3" 
                            placeholder="Enter ..."
                            v-model="descuento_concepto_operario_defecto"
                            ></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label>{{$t('general.generalfactura')}}</label>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-group">                                                
                                    <input 
                                        type="number" 
                                        class="form-control form-control-sm" 
                                        id="descuento-factura-defecto" 
                                        placeholder=""
                                        v-model="descuento_factura_defecto"
                                        >
                                    <div class="input-group-append">
                                        <span class="input-group-text form-control-sm">%</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group">                                                
                                    <input 
                                        type="number" 
                                        class="form-control form-control-sm" 
                                        id="descuento-cantidad-factura-defecto" 
                                        placeholder=""
                                        v-model="descuento_cantidad_factura_defecto"
                                        >
                                    <div class="input-group-append">
                                        <span class="input-group-text form-control-sm">&euro;</span>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <textarea 
                            class="form-control" 
                            rows="3" 
                            placeholder="Enter ..."
                            v-model="descuento_concepto_factura_defecto"
                            ></textarea>
                    </div>
                </div>
            </div>
        </div>
        <!-- Fin Descuentos -->
    </seccion-datos>
</template>

<script>

export default {
    props: {
        datos: {
            type: Object
        },
    },
    inject: ['guardar'],
    data() {
        return  {
            iva_trabajo_defecto: null,
            destinatario_trabajo_defecto: null,
            descuento_trabajo_defecto: null,
            descuento_trabajo_defecto_tipo_factura: null,
            descuento_concepto_trabajo_defecto: null,
            descuento_operario_defecto: null,
            descuento_concepto_operario_defecto: null,
            descuento_factura_defecto: null,
            descuento_cantidad_factura_defecto: null,
            descuento_concepto_factura_defecto: null,
            titulo:'Por defecto',
        }
    },
    computed: {
        todosImpuestosActivos() {
         //   const impuestos = this.$store.getters['datosApp/impuestos'];
            /*const impuestosActivos = impuestos.filter((imp) => {
                const ahora = new Date();
                const min = new Date(Date.parse(imp.fecha_aplicacion_iva));
                const max = new Date(Date.parse(imp.fecha_finaliza_iva))
                if (min <= ahora && max >= ahora) {
                    return true;
                }
                return false;
            });*/
            // return impuestosActivos;
            return 0;
        }
    },
    watch: {
        datos() {
            this.init();
        }
    },
    methods: {
        init() {
            if (this.datos) {
                for (let dato in this.$data) {
                    if (this.datos[dato] !== null && this.datos[dato] !== undefined) {
                        this[dato] = this.datos[dato];
                    }
                }
            }
        },
        nombreImpuesto(impuesto) {
            return (impuesto.nombre.trim().length > 0 ? impuesto.nombre.trim() : 'IVA') + ' ' + impuesto.valor_iva
        }
    },
    created() {
        this.init();
        this.titulo = this.$t('general.pordefecto');
    },
}
</script>